<template>
  <div class="tab-drawer">
    <el-dialog
      title="审计日志详情"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="30%"
      :close-on-click-modal="false"
    >
      <div>
        <span>活动数据</span>
        <els-monaco-editor
          style="height: 300px; border: 1px solid #ddd; margin-top: 10px"
          :showHeader="false"
          theme="vs-light"
          ref="editor"
          lang="json"
          v-model="data"
          :disabled="true"
        ></els-monaco-editor>
      </div>
      <div style="text-align: right; margin-top: 10px">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import elsMonacoEditor from "../../../components/els-monaco-editor/main.vue";
export default {
  name: "tab-drawer",
  components: {
    elsMonacoEditor,
  },
  data: () => {
    return {
      dialogVisible: false,
      data: {},
    };
  },
};
</script>
<style scoped lang="scss">
.tab-drawer {
  width: 100%;
  height: 100%;
  .tab-contain {
    width: 80%;
    height: 300px;
    border: 1px solid #ddd;
    padding: 10px;
    box-sizing: border-box;
    overflow: auto;
  }
}
</style>
