<template>
  <div class="client-myTab7">
    <div class="client-header">
      <el-date-picker
        v-model="value1"
        size="mini"
        type="datetimerange"
        :range-separator="lang.to"
        :start-placeholder="lang.startDate"
        :end-placeholder="lang.endDate"
      >
      </el-date-picker>
      <el-input
        v-model="value"
        :placeholder="lang.searchInputType"
        width="100px"
      ></el-input>
      <el-button
        size="mini"
        icon="el-icon-search"
        circle
        @click="reSearch"
      ></el-button>
      <el-button
        size="mini"
        icon="el-icon-refresh"
        class="iconSearch"
        circle
        @click="reset"
      ></el-button>
    </div>
    <div class="client-table">
      <els-table
        ref="elsform"
        style="height: 500px"
        :onload="onload"
        :column="column"
        :menuWidth="'80px'"
        :operate="lang.details"
      >
        <template v-slot:menu="{ row }">
          <span @click="editRow(row)">...</span>
        </template>
      </els-table>
    </div>
    <tab-drawer ref="tabDesc"></tab-drawer>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import { audit } from "../../../api/base/client";
import { clientAudit } from "@/api/manager/client-manage.js";
import { timeChange } from "../../../utils/client";
import tabDrawer from "../clientManage/desc-drawer.vue";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  name: "client-myTab7",
  mixins: [mixinsI18n],
  components: { elsTable, tabDrawer },
  props: {
    isData: Object,
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      value1: [
        new Date(new Date().getTime() - 3600 * 1000).getTime(),
        new Date().getTime(),
      ],
      start: new Date(new Date().getTime() - 3600 * 1000).getTime(),
      end: new Date().getTime(),
      value: "",
    };
  },
  computed: {
    column() {
      const data = [
        { prop: "createdTime", label: this.lang.time, valueType: "date" },
        { prop: "userName", label: this.lang.user },
        { prop: "actionType", label: this.lang.type },
        { prop: "actionStatus", label: this.lang.state },
      ];
      return data;
    },
  },
  watch: {
    value1(v) {
      if (v === null) {
        this.start = "";
        this.end = "";
        return;
      }
      this.start = !!v[0] ? timeChange(v[0]) : "";
      this.end = !!v[1] ? timeChange(v[1]) : "";
    },
  },
  methods: {
    // utilFilter(list, key) {
    //   return list.filter(
    //     (item) => Object.values(item).join(",").indexOf(key) != -1
    //   );
    // },
    async onload(page, query) {
      try {
        const pn = page.pageNum || 1;
        const ps = page.pageSize;
        const row = page.row;
        const d = {
          entityType: this.isData.nId.entityType,
          entityId: this.isData.nId.id,
          type: this.value,
        };
        const b = {
          tenantId: this.isData.nId.id,
          limit: ps,
          startTime: this.start,
          endTime: this.end,
          pageNum: pn,
          actionTypes: this.value,
        };
        let data;
        if (this.isData && this.isData.sign === "1") {
          //设备管理
          data = await clientAudit(d, b);
        } else {
          data = await audit(d, b); // 资产管理
        }
        let list = data.data;
        this.total = data.totalCount || data.data.length;
        // if (!!this.value) {
        //   const d = data.data.filter((item) => {
        //     return item.actionType.indexOf(this.value) > "-1";
        //   });
        //   if (d) {
        //     list = d;
        //   }
        //   this.total = list.totalCount || list.length;
        // } else {
        //   list = data.data;
        //   this.total = data.totalCount || data.data.length;
        // }
        return { list: list, total: this.total };
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    reset() {
      this.value = "";
      this.value1 = [];
      this.start = "";
      this.end = "";
      this.$refs.elsform.apiOnload();
    },
    reSearch() {
      this.$refs.elsform.apiSetPageNum();
      this.$refs.elsform.apiOnload();
    },
    editRow(row) {
      this.$refs.tabDesc.dialogVisible = true;
      this.$refs.tabDesc.data = row.actionData
        ? JSON.stringify(row.actionData, null, 4)
        : { name: "Main_test" };
      console.log(row);
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 20px;
  width: 240px;
}
::v-deep .el-input {
  width: auto;
}
::v-deep .el-pagination .el-input__inner {
  width: 100px !important;
}
.client-myTab7 {
  .client-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: flex-end;
    .iconSearch {
      margin-right: 10px;
    }
    .el-date-editor {
      min-width: 360px;
    }
  }
}
</style>
